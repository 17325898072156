.widget {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: auto;
  padding: 10px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  height: 100px;
  background-color: black;
  position: relative;

  .left {
    .icon {
      background-position: 50%;
      font-size: 50px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-start;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 10px;
      color: rgb(255, 255, 255);
    }

    .counter {
      font-size: 28px;
      font-weight: 300;
      color: white;
      text-align: right;
    }

    .link {
      width: max-content;
      font-size: 12px;
      border-bottom: 1px solid gray;
    }
  }
}
