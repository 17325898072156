.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top-new,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: flex;

      .heading-top {
        color: lightgray;
        font-size: 20px;
        margin-top: 15px;
      }
      .heading {
        color: black;
      }

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .right {
      flex: 2;

      .form-new {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;

        .formInput {
          width: 40%;

          .label-form {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon {
              cursor: pointer;
            }
          }

          .input-form {
            width: 100%;
            padding: 5px;
            border: 1px solid;
            margin-top: 10px;
          }
        }

        .createButton {
          width: 150px;
          padding: 10px;
          border: none;
          background-color: black;
          color: white;
          font-weight: bold;
          cursor: pointer;
          margin-top: 10px;
        }
      }
    }
  }
}
