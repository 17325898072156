.navbar {
  height: 70px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;

  .wrapper-search {
    width: 50vh;
    padding: 10px;
    display: flex;
    position: absolute;
    right: 0px;
    justify-content: space-around;
   
    
   
    }
  }

